<template>
  <div class="chart-by-users">
    <vue-apex-charts
      v-if="users.name.length && hasChartData"
      type="bar"
      :options="{
        plotOptions: {
          bar: {
            dataLabels: {
            },
          },
        },
        chart: {
          height: 360,
        },
        xaxis: {
          categories: users.name
        },
        ...chartOptions
      }"
      :series="[{
        name: $t('Total Amount'),
        data: users.amount,
      }, {
        name: $t('Total Invoiced Amount'),
        data: users.invoicedAmount,
      }]"
    />
    <div
      v-else
      class="text-center"
    >
      <span>{{ $t('table.no-data-to-show') }}</span>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    chartOptions: {
      type: Object,
      default: () => {},
    },
    users: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    hasChartData() {
      const amount = this.users.amount.reduce((a, b) => Number(a) + Number(b), 0)
      const invoicedAmount = this.users.invoicedAmount.reduce((a, b) => Number(a) + Number(b), 0)

      return Boolean(amount || invoicedAmount)
    },
  },
}
</script>

<style lang="scss">
.chart-by-users {
  .apexcharts-datalabels {
    display: none;
  }
}
.dark-layout {
  .chart-by-users {
    .apexcharts-legend-text {
      color: #e6e6e6 !important;
    }
  }
}
</style>
