<template>
  <b-overlay :show="isProcessing">
    <b-row
      id="tour-payment-history"
      :class="IS_MOBILE() ? 'flex-column flex-column-reverse' : ''"
    >
      <!-- Table -->
      <b-col md="8">
        <b-table
          id="payment-history-table"
          :items="rows"
          :fields="columns"
          responsive
          selectable
          @row-clicked="row => showDetails(row)"
        >
          <template #head()="row">
            <span class="text-capitalize">{{ $t(row.label) }}</span>
          </template>
          <template #cell(id)="row">
            {{ row.index + 1 }}
          </template>
          <template #cell(name)="row">
            <div class="d-flex align-items-center">
              <b-avatar
                :src="getAvatar(row.item.avatar)"
                :variant="getRandomVariant()"
                :text="getInitials(row.item.name)"
              />
              <p class="mb-0 ml-1">
                {{ IS_MOBILE() ? FORMAT_NAME(row.item.name) : row.item.name }}
              </p>
            </div>
          </template>
          <template #cell(totalWorkedTime)="row">
            {{ CONVERT_HM(row.item.activityDuration) }}
            <feather-icon
              v-if="row.item.manualDuration"
              v-b-tooltip.hover="`${$t('Manual Time')}: ${CONVERT_HM(row.item.manualDuration)}`"
              icon="InfoIcon"
              class="mb-1"
            />
          </template>
          <template #cell(totalAmount)="row">
            {{ formatAmount(row.item.activityAmount) }}
          </template>
          <template #cell(accountBalance)="row">
            {{ formatAmount(row.item.walletBalance) }}
          </template>
          <template #cell(totalInvoiced)="row">
            {{ formatAmount(ACCUMULATE(row.item.chartData.amount)) }}
          </template>
          <template #cell(actions)="row">
            <feather-icon
              :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              size="20"
            />
          </template>
          <template #row-details="row">
            <b-card class="payment-history-chart">
              <b-row
                v-if="row.item.chartData.invoicedAt.length"
                class="d-flex"
              >
                <!-- Monthly Chart of Each Users -->
                <b-col md="9">
                  <vue-apex-charts
                    type="bar"
                    :options="{
                      ...chartOptions,
                      xaxis: {
                        categories: row.item.chartData.invoicedAt
                      },
                      plotOptions: {
                        bar: {
                          dataLabels: {
                            position: 'top',
                          },
                        },
                      },
                      tooltip: {
                        custom: ({
                          series, seriesIndex, dataPointIndex, w,
                        }) => {
                          return apexTooltip(series, seriesIndex, dataPointIndex, w, row.item.chartData)
                        },
                        fixed: {
                          enabled: true,
                          position: 'bottomRight'
                        },
                      },
                    }"
                    :series="[{
                      name: $t('Total Amount'),
                      data: row.item.chartData.amount,
                    }]"
                  />
                </b-col>

                <!-- Legend -->
                <b-col
                  md="3"
                >
                  <p class="small">
                    {{ $t('Total Amount') }}:
                    <span class="font-weight-bold">
                      {{ `${currency}${row.item.activityAmount.toFixed(2)}` }}
                    </span>
                  </p>
                  <p class="small">
                    {{ $t('Invoiced Amount') }}:
                    <span class="font-weight-bold">
                      {{ `${currency}${row.item.chartData.amount.reduce((a, b) => Number(a) + Number(b), 0).toFixed(2)}` }}
                    </span>
                  </p>
                </b-col>
              </b-row>
              <div
                v-else
                class="text-center"
              >
                <span>{{ $t('table.no-data-to-show') }}</span>
              </div>
            </b-card>
          </template>
        </b-table>
        <b-pagination
          v-if="totalUsers > perPage"
          v-model="currentPage"
          class="float-right pb-4"
          :total-rows="totalUsers"
          :per-page="perPage"
          aria-controls="payment-history-table"
          @input="$emit('get-data', currentPage, perPage)"
        />
      </b-col>
      <b-col md="4">
        <!-- Chart by Users -->
        <b-card no-body>
          <chart-by-users
            v-if="users"
            class="chart-by-users"
            :chart-options="chartOptions"
            :users="users"
          />
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BOverlay, BTable, BCard, BPagination, BAvatar,
} from 'bootstrap-vue'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'
import ChartByUsers from './ChartByUsers.vue'

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BTable,
    BCard,
    BPagination,
    BAvatar,
    VueApexCharts,
    ChartByUsers,
  },
  props: {
    rows: {
      type: Array,
      default: () => {},
    },
    isProcessing: {
      type: Boolean,
      default: () => false,
    },
    totalUsers: {
      type: Number,
      default: () => 0,
    },
    currency: {
      type: String,
      default: () => '$',
    },
  },
  data() {
    return {
      chartOptions: {
        yaxis: {
          labels: {
            formatter: val => `${this.formatAmount(val)}`,
          },
        },
        dataLabels: {
          formatter: val => `${this.formatAmount(val)}`,
          offsetY: -20,
          style: {
            colors: ['#333'],
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            padding: 4,
          },
        },
      },
      currentPage: 1,
      perPage: 5,
      companyTimezone: this.$store.state.project.timezoneFilter.name,
    }
  },
  computed: {
    ...mapGetters('permissions', ['canEditCompany']),
    columns() {
      return [
        { key: 'id', label: 'S.No.', tdClass: 'w-10' },
        { key: 'name', tdClass: 'py-2 w-25' },
        { key: 'totalWorkedTime', thClass: 'px-1', tdClass: 'px-1' },
        { key: 'totalAmount', thClass: 'px-1', tdClass: 'px-1' },
        { key: 'totalInvoiced', thClass: 'px-1', tdClass: 'px-1' },
        ...(this.canEditCompany ? [{ key: 'accountBalance', thClass: 'px-1', tdClass: 'px-1' }] : []),
        { key: 'actions', label: '', tdClass: 'w-10' },
      ]
    },
    users() {
      return {
        name: this.rows.map(user => user.name),
        amount: this.rows.map(user => user.activityAmount.toFixed(2)),
        invoicedAmount: this.rows.map(user => this.ACCUMULATE(user.chartData.amount).toFixed(2)),
      }
    },
  },
  methods: {
    showDetails(row) {
      if (row._showDetails) {
        this.$set(row, '_showDetails', false)
      } else {
        this.rows.forEach(item => {
          this.$set(item, '_showDetails', false)
        })

        this.$nextTick(() => {
          this.$set(row, '_showDetails', true)
        })
      }
    },
    apexTooltip(series, seriesIndex, dataPointIndex, w, row) {
      let html = `
        <table>
          <tr>
            <th>${this.$t('S.No.')}</th>
            <th>${this.$t('From')}</th>
            <th>${this.$t('To')}</th>
            <th>${this.$t('Amount')}</th>
            <th>${this.$t('Status')}</th>
          </tr>
      `
      const invoices = row.data.filter(invoice => moment(w.globals.labels[dataPointIndex]).isSame(invoice.invoicedAt, 'month'))
      invoices.forEach((data, index) => {
        html += `
          <tr>
            <td>${index + 1}</td>
            <td>${moment(this.timeWithTimeZone(data.from, this.companyTimezone)).format('DD MMM, YYYY')}</td>
            <td>${moment(this.timeWithTimeZone(data.to, this.companyTimezone)).format('DD MMM, YYYY')}</td>
            <td>${this.formatAmount(data.paidAmount.toFixed(2))}</td>
            <td>${data.paidOn ? 'Paid' : 'Due'}
          </tr>
        `
      })

      html += '</table>'

      return (`
        <div class="card arrow_box">
          <div class="card-header bg-header p-1 font-weight-bold">${w.globals.labels[dataPointIndex]}</div>
          <div class="small card-body pb-0 mt-1">
            <div class="d-flex justify-content-between">
              <p class="font-weight-bold">
                ${this.$t('Invoice Details')}
              </p>
              <p class="text-right">
                ${this.$t('Total Amount')}:
                <span class="font-weight-bold">${this.formatAmount(series[seriesIndex][dataPointIndex])}</span>
              </p>
            </div>

            ${html}
          </div>
        </div>`)
    },
  },
}
</script>

<style lang="scss">
.payment-history-chart,
.chart-by-users {
  .bg-header,
  .apexcharts-tooltip-title {
    background-color: #ccf2ff !important;
    color: #21212a;
  }
}
.chart-by-users {
  padding: 12px;
}
.payment-history {
  .apexcharts-menu {
    width: 125px;
  }
}
</style>
